import axios, { AxiosResponse } from "axios";
import { Response } from "src/_metronic/helpers";
import { IUser } from "./_Models";

const API_CRM_URL = process.env.REACT_APP_CRM_API_URL
const GET_INFO_USER = `${API_CRM_URL}/users/getInfoUsers`

const getInfoUser = (): Promise<IUser | undefined> => {
  return axios
    .get(`${GET_INFO_USER}`)
    .then((response: AxiosResponse<Response<IUser>>) => response.data)
    .then((response: Response<IUser>) => response.data)
}


export { getInfoUser }